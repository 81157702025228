<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування партнера" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-card class="mb-3" title="Image *">
                  <div v-if="imgExisting">
                    <a style="display: block;" target="_blank" :href="imgExisting"><img style="max-width: 400px;" :src="imgExisting"></a>
                    <div><va-button small color="danger" @click="imgExisting = deleteImage(imgId) ? '' : imgExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    @input="delete errors.image"
                    dropzone
                    v-model="img"
                    style="margin-bottom: 25px;"
                  />
                  <span v-if="errors.image" style="color: red;">{{ this.errors.image[0] }}</span>
                  <!-- <va-button @click="uploadImage('image', 'PartnerMain-attribute-image', 'Partner[image]', img[0])">Завантажити</va-button> -->
                  <va-button @click="uploadImage('image', 'PartnerMain-attribute-image', 'main[image]', img[0])">Завантажити</va-button>
                  <!-- <button @click="test">test</button> -->
                </va-card>
                <va-input
                  label="Link *"
                  v-model="link"
                  :messages="['The recommended number of characters is 255']"
                  @input="delete errors.link"
                  :error="!!errors.link"
                  :error-messages="errors.link"
                />
                <va-input
                  label="Description *"
                  v-model="description"
                  :messages="['The recommended number of characters is 255']"
                  @input="delete errors.description"
                  :error="!!errors.description"
                  :error-messages="errors.description"
                />
                <!-- <va-toggle label="Published" small v-model="published"/>
                <va-input
                  label="Position"
                  v-model.number="position"
                  @input="delete errors.position"
                  :error="!!errors.position"
                  :error-messages="errors.position"
                /> -->
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
      img: [],
      imgId: '',
      imgExisting: '',
      description: '',
      // label: '',
      // position: '',
      // published: false,
      imagePath: '',
      link: '',

      sign: '',

      errors: [],

      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
    const generateRandomString = (length = 6) => Math.random().toString(20).substr(2, length)
    this.sign = generateRandomString(32)
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    uploadImage (attr, entityAttribute, param, file) {
      if (!file) {
        return
      }

      const formData = new FormData()
      formData.append('model_name', 'backend\\modules\\partner\\models\\Partner')
      formData.append('attribute', attr)
      formData.append('entity_attribute', entityAttribute)
      formData.append(param, file)
      formData.append('file_id', '0')
      formData.append('multiple', '0')
      if (this.$attrs.id) {
        formData.append('id', this.$attrs.id)
      } else {
        formData.append('sign', this.sign)
      }

      axios.post(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/widgets/partners/image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      )
        .then(response => {
          this.showToast('Successful upload')
          this.imagePath = response.data[0]
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id) {
      return axios.delete(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/widgets/partners/upload/${id}`)
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    submit () {
      const data = {
        main: {
          link: this.link,
          description: this.description,
          image: this.imagePath,
        },
        // label: this.label,
        // position: this.position,
        // published: !!this.published,
      //   sign: this.sign ? this.sign : null,
      }
      if (this.$attrs.id) { // update
        axios.put(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/widgets/partners/${this.$attrs.id}`, data)
          .then(response => {
            this.$router.push({ name: 'prylukyagro-widgets-partner' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      } else {
        axios.post(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/widgets/partners`, data)
          .then(response => {
            this.$router.push({ name: 'prylukyagro-widgets-partner' })
            this.showToast('Success')
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors
            }
            console.log(error)
            this.showToast('Error')
          })
      }
    },
    fetch () {
      if (this.$attrs.id) {
        axios.get(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/widgets/partners/${this.$attrs.id}`)
          .then(response => {
            console.log(response.data.data)
            this.link = response.data.main.link
            this.description = response.data.main.description
            // this.label = response.data.label
            // this.position = response.data.position
            // this.published = !!response.data.published
            // this.imgExisting = process.env.VUE_APP_AUTOSTRADA_ADMIN + response.data.image.originalSrc
            this.imgId = response.data.imageId
          })
          .catch(error => {
            console.log(error)
            this.showToast('Error')
          })
      } else {
        axios.get(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/widgets/partners/`)
      }
    },
    test () {
      console.log(this.img)
      console.log(this.imgId)
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}

</style>
